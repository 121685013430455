// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-bewoners-js": () => import("/opt/build/repo/src/templates/bewoners.js" /* webpackChunkName: "component---src-templates-bewoners-js" */),
  "component---src-templates-historie-js": () => import("/opt/build/repo/src/templates/historie.js" /* webpackChunkName: "component---src-templates-historie-js" */),
  "component---src-templates-jiskefet-js": () => import("/opt/build/repo/src/templates/jiskefet.js" /* webpackChunkName: "component---src-templates-jiskefet-js" */),
  "component---src-templates-home-js": () => import("/opt/build/repo/src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-oud-bewoners-js": () => import("/opt/build/repo/src/templates/oud-bewoners.js" /* webpackChunkName: "component---src-templates-oud-bewoners-js" */),
  "component---src-templates-prominent-js": () => import("/opt/build/repo/src/templates/prominent.js" /* webpackChunkName: "component---src-templates-prominent-js" */),
  "component---src-templates-streeplijst-js": () => import("/opt/build/repo/src/templates/streeplijst.js" /* webpackChunkName: "component---src-templates-streeplijst-js" */),
  "component---src-templates-schoonmaak-js": () => import("/opt/build/repo/src/templates/schoonmaak.js" /* webpackChunkName: "component---src-templates-schoonmaak-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

